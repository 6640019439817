<template>
  <b-container fluid>
    <vue-headful title="Login | FOMS" />

    <b-row align-v="center" align-h="center" class="mt-5">
      <b-col cols="6">
        <div>
          <b-card class="overflow-hidden text-left">
            <b-row>
              <b-col align-self="center" cols="2">
                <b-card-img src="/favicon.png" class="rounded-0" style="max-width: 100px"></b-card-img>
              </b-col>
              <b-col cols="10">
                <b-card-body title="Field Operations Management System - FOMS">
                  <b-card-text>
                    <b-form @submit.stop.prevent @submit="onSubmit" class="mt-5">
                      <!-- Email -->
                      <b-form-group id="email-group" label-for="email" label="" description="">
                        <b-form-input id="email" v-model="credentials.email" type="email" placeholder="Email address" aria-describedby="input-live-email-feedback" :state="usernameGood" required>
                        </b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-email-feedback"> Email is invalid. </b-form-invalid-feedback>
                      </b-form-group>

                      <!-- Password -->
                      <b-form-group id="pass-group" label-for="pass" label="" description="">
                        <b-form-input id="pass" v-model="credentials.password" type="password" placeholder="Password" aria-describedby="input-live-pass-feedback" :state="passwordGood" required>
                        </b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-pass-feedback"> Invalid password. Password must be 6 or more characters </b-form-invalid-feedback>
                      </b-form-group>

                      <b-button type="submit" variant="primary">
                        <b-spinner v-show="busy" small></b-spinner>
                        Sign In
                      </b-button>
                    </b-form>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>

            <!-- Forgot password -->
            <b-row align-content="start">
              <b-col cols="2"></b-col>
              <b-col class="ml-3">
                <b-button v-b-modal.reset-modal variant="link" class="small muted">Forgot Password?</b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <b-modal id="reset-modal" title="Reset Password" header-bg-variant="primary" @show="resetModal" @hidden="resetModal" @ok="reset_password">
      <div v-if="showRequestSent">
        <p>If the email provided is in our systems, a reset link has been sent to the email you provided. Follow the link to finish resetting your password.</p>
      </div>
      <div v-else>
        <form>
          <div class="form-group" v-bind:class="{ 'has-danger': !usernameGood }">
            <label for="forgot-email" class="form-control-label">Reset</label>
            <div class="input-group input-group-lg">
              <span class="input-group-addon"><i class="fa fa-envelope-o"></i></span>
              <input id="forgot-email" type="email" class="form-control" placeholder="Email" v-model="forgotPasswordEmail" value="" />
            </div>
            <p id="forgotPasswordHelpBlock" class="form-text text-muted">Please enter the email you signed up with.</p>
          </div>
        </form>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from 'vue'
import is from 'is_js'
import vueHeadful from 'vue-headful'

Vue.component('vue-headful', vueHeadful)

import authService from '../../api/auth'
import userService from '../../api/user'

export default {
  name: 'Login',

  data() {
    return {
      token: null,
      user: null,
      credentials: {
        email: '',
        password: ''
      },
      usernameGood: null,
      passwordGood: null,
      busy: false,
      forgotPasswordEmail: null,
      showRequestSent: false,
      okText: 'Reset',
      cancelText: 'Close'
    }
  },
  computed: {
    canLoginIn: function () {
      return this.credentials.email !== '' && this.credentials.password !== ''
    }
  },
  methods: {
    onSubmit() {
      let self = this
      this.busy = true

      let credentials = {
        email: this.credentials.email,
        password: this.credentials.password
      }

      try {
        authService
          .login(credentials)
          .then((response) => {
            if (is.not.undefined(response)) {
              this.token = response.access_token
              this.user = response.user
              this.$store.dispatch('login', response).then(() => {
                self.busy = false
                self.preload()
              })
            } else {
              self.busy = false
            }
          })
          .catch((errors) => this.failure(errors))
      } catch (e) {
        console.log(e)
      }
    },

    preload() {
      this.$store.dispatch('getFirms', { limit: -1, firm: this.user.firm_id })
      this.$store.dispatch('getLocationData', { limit: -1, firm: this.user.firm_id })
      this.$store.dispatch('getPeople', { limit: -1, firm: this.user.firm_id })
      this.$store.dispatch('getRegistrationData', { limit: -1, firm: this.user.firm_id })
      this.setRoles()
    },

    setRoles() {
      userService
        .getRoles()
        .then((response) => {
          this.$store.dispatch('setRoles', response)
          if (this.$route.query.redir === '/' || !this.$route.query.redir) {
            let path = this.$store.getters.isQAofficer ? '/qa' : '/analytics'
            this.$router.push(path)
          } else {
            this.$router.push(this.$route.query.redir)
          }
        })
        .catch((errors) => this.failure(errors))
    },

    reset(el) {
      if (el === 'p') {
        this.passwordGood = null
      } else {
        this.usernameGood = null
      }
    },

    hideModal() {
      this.okText = 'Reset'
      this.showRequestSent = false
      this.$nextTick(() => {
        this.$bvModal.hide('reset-modal')
      })
    },
    resetModal() {
      this.forgotPasswordEmail = ''
    },
    reset_password(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.okText === 'Reset') {
        this.okText = 'Done'
        this.showRequestSent = true

        let creds = {
          email: this.forgotPasswordEmail
        }

        authService
          .send_reset_email(creds)
          .then(() => {
            this.hideModal()
          })
          .catch(() => {})
      } else {
        this.hideModal()
      }
    },

    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        toaster: 'b-toaster-top-center',
        variant: variant,
        solid: true
      })
    },

    failure(errors) {
      this.busy = false
      try {
        if (errors.hasOwnProperty('errors')) {
          this.usernameGood = !errors.errors.hasOwnProperty('email')
          if (errors.errors.hasOwnProperty('password') || errors.message.includes('Unauthorized')) {
            this.passwordGood = false
          }
        } else if (errors.hasOwnProperty('message') && errors.message.includes('Unauthorized')) {
          this.usernameGood = true
          this.passwordGood = false
        } else if (errors.hasOwnProperty('status') && errors.status === 'error') {
          this.makeToast('danger', 'An Error Occured', errors.message)
        }
      } catch (e) {
        this.syslog(e)
      }
      this.$store.dispatch('failedlogin', errors)
    }
  }
}
</script>
